@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
html {
  font-family: "Roboto", system-ui, sans-serif;
}



body {

}

.top-header-icon {
  @apply text-white fill-white sm:h-5 sm:w-5 md:h-8 md:w-8 py-1;
}

@media screen and (max-width: 640px) {
  body {
    overflow-x: hidden;
  }
}

@media print {
  @page {
    size: auto;
    margin: 0;
  }
  .no-print{
    display: none !important;
  }
  .show-print {
    display: flex !important;
  }
}

.detail-article p {
  @apply mb-[1rem]
}

.detail-article img {
  @apply !relative object-contain !h-[unset];
}

.detail-article iframe {
  @apply max-w-full sm:max-w-none max-h-48 sm:max-h-none;
}

#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

html,
body {
  position: relative;
  height: 100%;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  width: 500px;
}

.swiper-custom .swiper-slide {
  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
  }

  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  /* custom scroll shadow */
  @layer utilities {
    .custom-scroll::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
    .custom-scroll::-webkit-scrollbar-track {
      background: var(--theme-secondary);
      border-radius: 4px;
    }
    .custom-scroll::-webkit-scrollbar-thumb {
      background: var(--theme-primary);
      border-radius: 4px;
      border: 2px solid var(--theme-secondary);
    }
    .custom-scroll::-webkit-scrollbar-thumb:hover {
      background: var(--theme-primary);
    }
  }
}